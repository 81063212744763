import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// components
import { Navbar, BackgroundSectionGeneric } from 'src/components/common';
import { SectionHeader } from 'src/components/Photography';

const listings = [
  {
    name: 'IMF',
    sub: 'Innovative Micro Finance',
  },
  {
    name: 'Distant relative',
  },

  {
    name: 'Bead the change',
  },

  {
    name: 'The Rajwa Company',
    path: 'rajwa-company-lance',
    sub: 'Lance Gross',
    fullWidth: true,
  },
];

const DocumentaryPage = (props) => {
  return (
    <>
      <Navbar dark />
      <StyledSubSectionLayout
        style={{
          marginTop: '100px',
        }}
      >
        <div className="container-lg">
          <SectionHeader sectionName="Documentary" />
        </div>

        <div className="listing">
          {listings.map((item, i) => {
            const path = item.path ? item.path : item.name.toLowerCase().split(' ').join('-');

            return (
              <BackgroundSectionGeneric
                key={i}
                name={path}
                className={`${item.fullWidth && 'list-middle-item'} list-item `}
              >
                <Link to={`${props.path}${path}`}>
                  <div key={i}>
                    <h2>{item.name}</h2>
                    {item.sub && <p>{item.sub}</p>}
                  </div>
                </Link>
              </BackgroundSectionGeneric>
            );
          })}
        </div>
      </StyledSubSectionLayout>

      {/* <Footer /> */}
    </>
  );
};

const StyledSubSectionLayout = styled.section`
  min-height: 100vh;

  .listing {
    margin-bottom: 200px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, calc(100% / 3));
    grid-template-rows: repeat(3, auto);
    grid-gap: 10px;
    margin: 0 auto;

    @media only screen and (max-width: 600px) {
      display: flex;
      flex-wrap: wrap;
    }

    .list-middle-item {
      grid-column-start: span 3;
    }

    .list-item {
      height: 530px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-size: contain;
      box-shadow: 0 30px 60px rgba(0, 0, 0, 0.12);

      @media only screen and (max-width: 600px) {
        width: 100%;
        height: 400px;
      }

      @media only screen and (max-width: 600px) {
        width: 100%;
        height: 400px;
      }

      :hover {
        filter: brightness(87.5%);
      }

      h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 2rem;
        padding: 0 20px;
        background: rgba(0, 0, 0, 1);
        text-align: center;
        color: white;
        padding: 5px 10px;
      }

      p {
        background: rgba(0, 0, 0, 1);
        color: white;
        text-align: center;
        padding: 5px 10px;
      }
    }
  }
`;

export default DocumentaryPage;
